// Dependencies
import { observable, action } from 'mobx';

class ErrorStore {
	@observable error = null;

	handle = action(error => {
		this.error = error;
	});

	clear = action(() => {
		this.error = null;
	});
}

export default new ErrorStore();
