// Stores
import sessionStore from './sessionStore';
import errorStore from './errorStore';
import messageStore from './messageStore';
import exampleTrackStore from './exampleTrackStore';
import trackStore from './trackStore';
import sampleStore from './sampleStore';
import { useStaticRendering } from 'mobx-react';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);

export const stores = {
	sessionStore,
	errorStore,
	exampleTrackStore,
	messageStore,
	sampleStore,
	trackStore,
};
