// Dependencies
import { observable, action } from 'mobx';

class ExampleTrackStore {
	@observable currentTrack = null;
	@observable currentVideo = null;
	@observable tracks = [
		{
			id: 1,
			name: 'Breezeblocks',
			time_length: 261,
			song_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/breezeblocks/audio.mp3',
			poster_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/breezeblocks/poster.jpg',
			video_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/breezeblocks/video.m3u8',
		},
		{
			id: 2,
			name: 'Lonely Night',
			time_length: 166,
			song_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/lonely-night/audio.mp3',
			poster_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/lonely-night/poster.jpg',
			video_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/lonely-night/video.m3u8',
		},
		{
			id: 3,
			name: 'Saxolove',
			time_length: 130,
			song_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/saxolove/audio.mp3',
			poster_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/saxolove/poster.jpg',
			video_url:
				'https://danblaax-cdn-production.fra1.digitaloceanspaces.com/example-videos/saxolove/video.m3u8',
		},
	];

	setCurrentTrack = action((track) => {
		this.currentTrack = track;
	});

	setCurrentVideo = action((track) => {
		this.currentVideo = track;
	});

	reset = action(() => {
		this.tracks = [];
		this.currentTrack = null;
		this.currentVideo = null;
	});
}

export default new ExampleTrackStore();
