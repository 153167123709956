// Dependencies
import { observable, action } from 'mobx';

// Services
import { sampleService } from '../services';

class SampleStore {
	@observable samples = [];

	getAll = action(async () => {
		const { data } = await sampleService.getAll();
		return (this.samples = data);
	});
}

export default new SampleStore();
