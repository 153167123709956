import './Background.scss';

const Background = () => (
	<div id="background-container">
		<div id="background" />
		<div id="background-layer-1" />
	</div>
);

export default Background;
