// Dependencies
import { observable, action } from 'mobx';

// Services
import { trackService } from '../services';

class TrackStore {
	@observable currentTrack = null;
	@observable currentVideo = null;
	@observable tracks = [];

	getAll = action(async () => {
		const { data } = await trackService.getAll();
		return (this.tracks = data);
	});

	setCurrentTrack = action((track) => {
		this.currentTrack = track;
	});

	setCurrentVideo = action((track) => {
		this.currentVideo = track;
	});

	reset = action(() => {
		this.tracks = [];
		this.currentTrack = null;
		this.currentVideo = null;
	});
}

export default new TrackStore();
