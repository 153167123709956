import App from 'next/app';
import React, { Component } from 'react';
import { stores } from '../src/stores';
import { Provider } from 'mobx-react';
import { StripeProvider } from 'react-stripe-elements';
import Head from 'next/head';
import { Theme } from '@anephenix/ui';
import Background from '../src/components/background/Background';
import { stripeApiKey, env, sentryDSN, googleAnalytics } from '../src/config';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

Sentry.init({
	dsn: sentryDSN,
	environment: env,
});

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { eventId: null, hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.hasError) {
			//render fallback UI
			return (
				<button
					onClick={() =>
						Sentry.showReportDialog({ eventId: this.state.eventId })
					}
				>
					Report feedback
				</button>
			);
		}
		return this.props.children;
	}
}

const Hotjar = () => (
	<script
		async={true}
		dangerouslySetInnerHTML={{
			__html: `(function (h, o, t, j, a, r) {
		h.hj = h.hj ||
		function () {
			(h.hj.q = h.hj.q || []).push(arguments);
		};
		h._hjSettings = { hjid: 1794221, hjsv: 6 };
		a = o.getElementsByTagName('head')[0];
		r = o.createElement('script');
		r.async = 1;
		r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
		a.appendChild(r);
		})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`,
		}}
	/>
);

class MyMobxApp extends App {
	constructor() {
		super();
		this.state = { stripe: null };
	}

	componentDidMount() {
		if (window.Stripe) {
			this.setState({ stripe: window.Stripe(stripeApiKey) });
		} else {
			const self = this;
			let check = null;
			check = () => {
				if (window.Stripe) {
					self.setState({ stripe: window.Stripe(stripeApiKey) });
				} else {
					// Check again in 5 seconds
					setTimeout(check, 5000);
				}
			};
			check();
		}
		if (window) {
			ReactGA.initialize(googleAnalytics);
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		const { Component, pageProps } = this.props;
		const hotjar = env === 'production' ? <Hotjar /> : null;
		return (
			<ErrorBoundary>
				<Provider {...stores}>
					<Head>
						<meta
							name="viewport"
							content="initial-scale=1.0, width=device-width"
						/>
						<meta name="og:title" content="Dan Blaax" />
						<meta
							name="og:description"
							content="Welcome to the official site of Dan Blaax"
						/>
						<meta name="theme-color" content="#000" />
						<meta
							name="og:image"
							content="https://danblaax-cdn-production.fra1.digitaloceanspaces.com/website-assets/social-image.jpg"
						/>
						<script
							src="https://js.stripe.com/v3/"
							async={true}
						></script>
						{hotjar}
						<title>Dan Blaax</title>
					</Head>
					<Theme>
						<Background />
						<div className="page" id="page-content">
							<StripeProvider stripe={this.state.stripe}>
								<Component {...pageProps} />
							</StripeProvider>
						</div>
					</Theme>
				</Provider>
			</ErrorBoundary>
		);
	}
}
export default MyMobxApp;
